import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useLocation } from "react-router-dom";
import { useData } from "../../contexts/DataContext";
import { EditNote, AttachFile, Work, School, Chat, ExitToApp, Menu } from "@mui/icons-material";
import { Typography, Avatar, IconButton } from "@mui/material";

const menuItems = [
  { text: "Job Application", icon: <EditNote />, path: "/job-application" },
  { text: "Career Onboarding", icon: <School />, path: "/career-onboarding" },
  { text: "Job Match", icon: <Work />, path: "/job-match" },
  { text: "Career Coach Assistant", icon: <Chat />, path: "/career-coach-assistant-voice-bot" },
  { text: "Interview Assistant", icon: <Chat />, path: "/interview-assistant-voice-bot" },
  { text: "Next Step Blueprint", icon: <Chat />, path: "/next-step-blueprint-voice-bot" },
  { text: "File Management", icon: <AttachFile />, path: "/file-management" },
];

const LeftMenuLayout = ({ children }) => {
  const { handleLogout } = useData();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (event.clientX <= 10) {
        setOpen(true);
      }
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const handleMenuClick = (path) => {
    navigate(path);
    setOpen(false);
  };

  const handleLogoutClick = async () => {
    await handleLogout();
    navigate("/");
  };

  const DrawerList = (
    <Box
      sx={{
        width: 280,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        bgcolor: "#1e1e1e",
        color: "#ffffff",
      }}
    >
      <Box sx={{ p: 2, display: "flex", alignItems: "center", borderBottom: 1, borderColor: "rgba(255, 255, 255, 0.12)" }}>
        <Avatar sx={{ mr: 2, bgcolor: "#4caf50" }}>AI</Avatar>
        <Typography variant="h6">AI Career Assistant</Typography>
      </Box>
      <List sx={{ flexGrow: 1, pt: 0 }}>
        {menuItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              selected={location.pathname === item.path}
              onClick={() => handleMenuClick(item.path)}
              sx={{
                borderRadius: 2,
                m: 0.5,
                "&.Mui-selected": {
                  bgcolor: "rgba(76, 175, 80, 0.2)",
                },
                "&:hover": {
                  bgcolor: "rgba(255, 255, 255, 0.08)",
                },
              }}
            >
              <ListItemIcon sx={{ color: "#4caf50" }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider sx={{ bgcolor: "rgba(255, 255, 255, 0.12)" }} />
      <ListItem disablePadding>
        <ListItemButton onClick={handleLogoutClick} sx={{ borderRadius: 2, m: 0.5 }}>
          <ListItemIcon sx={{ color: "#f44336" }}><ExitToApp /></ListItemIcon>
          <ListItemText primary="Logout" sx={{ color: "#f44336" }} />
        </ListItemButton>
      </ListItem>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="temporary"
        open={open}
        onClose={() => setOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            width: 280,
            boxSizing: "border-box",
            boxShadow: 3,
          },
        }}
      >
        {DrawerList}
      </Drawer>
      <Box
        sx={{
          position: "fixed",
          top: 16,
          left: 16,
          zIndex: 1200,
          display: open ? "none" : "block",
        }}
      >
        <IconButton
          onClick={() => setOpen(true)}
          sx={{
            bgcolor: "#1e1e1e",
            color: "#ffffff",
            "&:hover": {
              bgcolor: "#333333",
            },
          }}
        >
          <Menu />
        </IconButton>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {children}
      </Box>
    </Box>
  );
};

export default LeftMenuLayout;
