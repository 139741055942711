import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
  OutlinedInput,
  FormLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useData } from "../../contexts/DataContext";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.7)",
  marginBottom: theme.spacing(1),
  "&.Mui-focused": {
    color: "rgba(255, 255, 255, 0.7)",
  },
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-checked": {
    color: "#4caf50",
  },
  "& .MuiSvgIcon-root": {
    // This ensures the checkbox icon is visible
    fontSize: 24,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.9)",
  "&:hover": {
    color: "rgba(255, 255, 255, 1)",
  },
  "& .MuiFormControlLabel-label": {
    fontSize: "0.9rem",
  },
}));

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.23)",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.5)",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.5)",
  },
  color: "rgba(255, 255, 255, 0.9)",
  "&::placeholder": {
    color: "rgba(255, 255, 255, 0.5)",
  },
}));

const workPreferencesOptions = [
  "Frequent travel",
  "Working in an office",
  "Managing people",
  "Working outside 9-5",
  "Public speaking",
  "High-pressure environments",
  "Remote work",
  "Frequent meetings",
  "Client-facing roles",
];

export default function WorkPreferencesForm() {
  const { updateFormData, formData } = useData();

  const [formState, setFormState] = useState(() => {
    const notInterestedIn =
      typeof formData?.form_info?.notInterestedIn === "string"
        ? formData.form_info.notInterestedIn
            .split(", ")
            .map((item) => item.trim())
        : formData?.form_info?.notInterestedIn || [];

    return {
      notInterestedIn: notInterestedIn,
      otherNotInterested: formData?.form_info?.otherNotInterested || "",
      workRelatedActivities: formData?.form_info?.workRelatedActivities || "",
    };
  });

  const handleCheckboxChange = (option) => {
    setFormState((prevState) => {
      const updatedNotInterestedIn = prevState.notInterestedIn.includes(option)
        ? prevState.notInterestedIn.filter((item) => item !== option)
        : [...prevState.notInterestedIn, option];
      return { ...prevState, notInterestedIn: updatedNotInterestedIn };
    });
  };

  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    updateFormData("notInterestedIn", formState.notInterestedIn);
    updateFormData("workRelatedActivities", formState.workRelatedActivities);
    updateFormData("otherNotInterested", formState.otherNotInterested);
  }, [formState]);

  return (
    <Box sx={{ color: "#ffffff", padding: 3 }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
        Work Preferences
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StyledFormControl component="fieldset" fullWidth>
            <StyledFormLabel component="legend">
              Select things you're NOT interested in:
            </StyledFormLabel>
            <Grid container spacing={2}>
              {workPreferencesOptions.map((option) => (
                <Grid item xs={12} sm={6} md={4} key={option}>
                  <StyledFormControlLabel
                    control={
                      <StyledCheckbox
                        checked={formState.notInterestedIn.includes(option)}
                        onChange={() => handleCheckboxChange(option)}
                      />
                    }
                    label={option}
                  />
                </Grid>
              ))}
            </Grid>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledFormControl fullWidth>
            <StyledFormLabel htmlFor="otherNotInterested">
              Other things you're not interested in:
            </StyledFormLabel>
            <StyledOutlinedInput
              id="otherNotInterested"
              name="otherNotInterested"
              value={formState.otherNotInterested}
              onChange={handleTextChange}
              placeholder="Other"
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledFormControl fullWidth>
            <StyledFormLabel htmlFor="workRelatedActivities">
              What work-related activities do you enjoy the most?
            </StyledFormLabel>
            <StyledOutlinedInput
              id="workRelatedActivities"
              name="workRelatedActivities"
              value={formState.workRelatedActivities}
              onChange={handleTextChange}
              placeholder="Describe activities you enjoy"
              multiline
              rows={3}
            />
          </StyledFormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
