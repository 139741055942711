import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { lightTheme } from "./styles/theme";
import BotPage from "./Pages/BotPage";
import UserAuthPage from "./Pages/UserAuthPage";
import JobApplicationPage from "./Pages/JobApplicationPage";
import OnBoardingHome from "./components/OnboardingForms/OnBoardingHome";
import JobMatch from "./components/JobMatch/JobMatch";
import { DataProvider } from "./contexts/DataContext";
import PrivateRoute from "./components/Auth/PrivateRoute";
import PublicRoute from "./components/Auth/PublicRoute";
import ProfileMenu from "./components/Layout/ProfileMenu";
import StripeRedirect from "./Pages/stripe";
import CareerOnboardingPage from "./Pages/CareerOnboardingPage";
import FileManagement from "./components/FileManagement/FileManagement";

const App = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <DataProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={<PublicRoute element={<UserAuthPage />} />}
            />
            <Route
              path="/interview-assistant-voice-bot"
              element={
                <>
                  <PrivateRoute element={<BotPage />} />
                  <ProfileMenu />
                </>
              }
            />
            <Route
              path="/career-coach-assistant-voice-bot"
              element={
                <>
                  <PrivateRoute element={<BotPage />} />
                  <ProfileMenu />
                </>
              }
            />
             <Route
              path="/next-step-blueprint-voice-bot"
              element={
                <>
                  <PrivateRoute element={<BotPage />} />
                  <ProfileMenu />
                </>
              }
            />
            <Route
              path="/job-application"
              element={
                <>
                  <PrivateRoute element={<JobApplicationPage />} />
                  <ProfileMenu />
                </>
              }
            />
            <Route
              path="/career-onboarding"
              element={
                <>
                  <PrivateRoute element={<CareerOnboardingPage />} />
                  <ProfileMenu />
                </>
              }
            />
            <Route
              path="/onboarding-home"
              element={
                <>
                  <PrivateRoute element={<OnBoardingHome />} />
                  <ProfileMenu />
                </>
              }
            />
            <Route
              path="/job-match"
              element={
                <>
                  <PrivateRoute element={<JobMatch />} />
                  <ProfileMenu />
                </>
              }
            />
            <Route path="/stripe" element={<StripeRedirect />} />
            <Route path="/file-management" element={<FileManagement/>} />
          </Routes>
        </Router>
      </DataProvider>
    </ThemeProvider>
  );
};
export default App;
