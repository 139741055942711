import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  Grid,
  Box,
  Typography,
  Paper,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";
import ToastNotification, {
  showToast,
} from "../OnboardingForms/ToastNotification";
import PocketBase from "pocketbase";
import { useData } from "../../contexts/DataContext";
const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const LogInSignUpForm = () => {
  const navigate = useNavigate();
  const { fetchAndSetUserData } = useData();

  const handleGoogleAuth = async () => {
    try {
      const authData = await pb
        .collection("users")
        .authWithOAuth2({ provider: "google" });
      if (authData) {
        localStorage.setItem("AccessToken", authData.token);
        localStorage.setItem("email", authData.meta.email);
        // Check subscription status
        const response = await fetch(
          `https://coachdemo.hrbrain.ai/api/check-subscription`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: authData.meta.email }),
          }
        );
        showToast("Checking subscription status...", "info");
        const subscriptionData = await response.json();
        if (subscriptionData.has_active_subscription) {
          showToast("Subscription status checked successfully", "success");
          // Fetch user data and check onboarding status
          navigate("/onboarding-home", { replace: true });
          await fetchAndSetUserData();
        } else {
          window.location.href = process.env.REACT_APP_STRIPE_CHECKOUT_URL;
        }
      }
    } catch (error) {
      showToast("An error occurred. Please try again.", "error");
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            'url("https://www.simplilearn.com/ice9/free_resources_article_thumb/ai_in_iot.jpg")',
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box sx={{ mt: 1, width: "100%" }}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleAuth}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign in with Google
            </Button>
          </Box>
        </Box>
      </Grid>
      <ToastNotification />
    </Grid>
  );
};

export default LogInSignUpForm;
