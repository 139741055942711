import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const StripeRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = process.env.REACT_APP_STRIPE_CHECKOUT_URL;
  }, [navigate]);

  return null;
};

export default StripeRedirect;
