import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";

const SuggestionCard = ({ onSuggestionClick, suggestions }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        marginBottom: 2,
        height: "400px",
      }}
    >
      <Typography
        variant="body2"
        sx={{ textAlign: "center", color: "#ffffff", fontSize: "16px" }}
      >
        Suggestions
      </Typography>

      {suggestions?.map((suggestion, index) => (
        <Card
          key={index}
          sx={{
            height: "fit-content",
            cursor: "pointer",
            backgroundColor: "#1c1c1c",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#333333",
              transform: "scale(1.05)",
            },
            transition: "transform 0.2s",
          }}
          onClick={() => onSuggestionClick(suggestion)}
        >
          <CardContent>
            <Typography variant="body2">{suggestion}</Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default SuggestionCard;
