import React, { useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Grid,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useData } from "../../contexts/DataContext";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.7)",
  marginBottom: theme.spacing(1),
  "&.Mui-focused": {
    color: "rgba(255, 255, 255, 0.7)",
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.23)",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.5)",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.5)",
  },
  color: "rgba(255, 255, 255, 0.9)",
  "& .MuiSelect-icon": {
    color: "rgba(255, 255, 255, 0.7)",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-checked": {
    color: "#4caf50", // Green color for selected radio
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.9)",
  "&:hover": {
    color: "rgba(255, 255, 255, 1)",
  },
  "& .MuiFormControlLabel-label": {
    "&.Mui-focused": {
      color: "rgba(255, 255, 255, 0.9)",
    },
  },
}));

export default function DemoGraphicForm() {
  const { updateFormData, formData } = useData();
  const [age, setAge] = useState(formData.form_info.Age || "");
  const [gender, setGender] = useState(formData.form_info.Gender || "");

  const handleAgeChange = (event) => {
    const newAge = event.target.value;
    setAge(newAge);
    updateFormData("Age", newAge);
  };

  const handleGenderChange = (event) => {
    const newGender = event.target.value;
    setGender(newGender);
    updateFormData("Gender", newGender);
  };

  return (
    <Box sx={{ color: "#ffffff", padding: 3 }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
        Demographic Information
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <StyledFormControl fullWidth>
            <StyledFormLabel htmlFor="age">Age</StyledFormLabel>
            <StyledSelect
              id="age"
              value={age}
              onChange={handleAgeChange}
              displayEmpty
            >
              <StyledMenuItem value="" disabled>
                <em>Select age</em>
              </StyledMenuItem>
              {[...Array(100).keys()].map((num) => (
                <StyledMenuItem key={num} value={num + 1}>
                  {num + 1}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledFormControl component="fieldset" fullWidth>
            <StyledFormLabel component="legend">Gender</StyledFormLabel>
            <RadioGroup value={gender} onChange={handleGenderChange}>
              {[
                "Male",
                "Female",
                "Non-binary",
                "Prefer not to say",
                "Other",
              ].map((option) => (
                <StyledFormControlLabel
                  key={option}
                  value={option.toLowerCase()}
                  control={<StyledRadio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </StyledFormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
