import React from "react";
import { Menu, MenuItem, IconButton, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useData } from "../../contexts/DataContext";

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { user, handleLogout } = useData();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = async () => {
    await handleLogout();
    navigate("/");
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          position: "fixed",
          top: 20,
          right: 20,
          zIndex: 1200,
        }}
      >
        <Avatar
          alt={"careercoach"}
          src="https://as2.ftcdn.net/v2/jpg/07/57/68/01/1000_F_757680115_RAsDb6RgxgeFQap4EAY0AOVpKUUP967X.jpg"
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            position: "absolute",
            top: "60px",
            right: 0,
            marginTop: 10,
          },
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 2,
            width: 100,
          },
        }}
      >
        {/* <MenuItem>Profile</MenuItem> */}
        <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
