// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#job-match-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-size: 400% 400%;
  animation: backgroundAnimation 15s ease infinite;
  position: relative;
  color: #fff;
}

@keyframes backgroundAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

#job-card {
  background-color: inherit;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

#job-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

#job-card .MuiTypography-root {
  color: #fff;
}

#job-card .MuiChip-root {
  background-color: #1e88e5;
  color: #fff;
}

#job-card .MuiList-root {
  color: #fff;
}

#job-card .MuiListItem-root {
  padding-top: 0;
  padding-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/JobMatch.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,0BAA0B;EAC1B,gDAAgD;EAChD,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE;IACE,2BAA2B;EAC7B;;EAEA;IACE,6BAA6B;EAC/B;;EAEA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,wCAAwC;EACxC,mBAAmB;EACnB,mEAAmE;AACrE;;AAEA;EACE,4BAA4B;EAC5B,yCAAyC;AAC3C;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":["#job-match-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  min-height: 100vh;\n  background-size: 400% 400%;\n  animation: backgroundAnimation 15s ease infinite;\n  position: relative;\n  color: #fff;\n}\n\n@keyframes backgroundAnimation {\n  0% {\n    background-position: 0% 50%;\n  }\n\n  50% {\n    background-position: 100% 50%;\n  }\n\n  100% {\n    background-position: 0% 50%;\n  }\n}\n\n#job-card {\n  background-color: inherit;\n  color: #fff;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n  border-radius: 20px;\n  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;\n}\n\n#job-card:hover {\n  transform: translateY(-10px);\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);\n}\n\n#job-card .MuiTypography-root {\n  color: #fff;\n}\n\n#job-card .MuiChip-root {\n  background-color: #1e88e5;\n  color: #fff;\n}\n\n#job-card .MuiList-root {\n  color: #fff;\n}\n\n#job-card .MuiListItem-root {\n  padding-top: 0;\n  padding-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
