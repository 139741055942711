import React, { useEffect } from "react";
import CareerOnboardingFormLayout from "../components/OnboardingForms/CareerOnboardingFormLayout";
import LeftMenuLayout from "../components/Layout/LeftMenuLayout";
import BotBgAnimation from "../components/Animation/BotBgAnimation";
import ToastNotification from "../components/OnboardingForms/ToastNotification";

const CareerOnboardingPage = () => {
  return (
    <>
      <LeftMenuLayout>
        <BotBgAnimation />
        <div
          style={{
            backgroundSize: "400% 400%",
            animation: "backgroundAnimation 15s ease infinite",
            minHeight: "100vh",
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <CareerOnboardingFormLayout />
        </div>
        <ToastNotification />
      </LeftMenuLayout>
    </>
  );
};

export default CareerOnboardingPage;
