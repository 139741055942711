import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ element }) => {
  const email = localStorage.getItem('email');
  const password = localStorage.getItem('password');

  // If the user is authenticated, redirect to a protected page
  if (email && password) {
    return <Navigate to="/onboarding-home" replace />;
  }

  // If the user is not authenticated, render the element
  return element;
};

export default PublicRoute;
