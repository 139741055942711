import React from "react";
import {
  Box,
  Typography,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LeftMenuLayout from "../../components/Layout/LeftMenuLayout";
import { useData } from "../../contexts/DataContext";
import "../../styles/JobMatch.css";
import LoadingSpinner from "../LoadingSpinner";
import BotBgAnimation from "../Animation/BotBgAnimation";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "rgba(255, 255, 255, 0.05)",
  backdropFilter: "blur(10px)",
  borderRadius: "15px",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
  },
}));

const JobMatch = () => {
  const { spinner, formData } = useData();
  return (
    <LeftMenuLayout>
      <BotBgAnimation />
      <Box
        sx={{
          minHeight: "100vh",
          backgroundSize: "400% 400%",
          animation: "backgroundAnimation 15s ease infinite",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: { xs: 2, sm: 3, md: 4 },
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ color: "#ffffff", mb: 4 }}>
          Job Matches
        </Typography>
        {formData?.form_info?.job_match?.length > 0 ? (
          <Grid container spacing={4} justifyContent="center">
            {formData?.form_info?.job_match.map((job, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <StyledPaper elevation={3}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: "bold", color: "#1E88E5", mb: 1 }}
                  >
                    {job.job_title}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5, color: "#fff" }}
                    color="text.secondary"
                  >
                    {job.company}
                  </Typography>
                  <Chip
                    label={`Score: ${job.score} %`}
                    color="primary"
                    sx={{
                      mb: 2,
                      backgroundColor: "#1E88E5",
                      fontWeight: "bold",
                      fontSize: "16px",
                      height: "32px",
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "#4CAF50", mt: 2 }}
                  >
                    Pros:
                  </Typography>
                  <List dense sx={{ mb: 2 }}>
                    {job.pros.map((pro, idx) => (
                      <ListItem key={idx} sx={{ py: 0 }}>
                        <ListItemText
                          primary={`• ${pro}`}
                          sx={{ color: "#fff" }}
                        />
                      </ListItem>
                    ))}
                  </List>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "#F44336" }}
                  >
                    Cons:
                  </Typography>
                  <List dense>
                    {job.cons.map((con, idx) => (
                      <ListItem key={idx} sx={{ py: 0 }}>
                        <ListItemText
                          primary={`• ${con}`}
                          sx={{ color: "#fff" }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </StyledPaper>
              </Grid>
            ))}
          </Grid>
        ) : (
          !spinner && (
            <Typography variant="h6" sx={{ color: "gray", mt: 4 }}>
              No job matches available.
            </Typography>
          )
        )}
        {spinner && <LoadingSpinner />}
      </Box>
    </LeftMenuLayout>
  );
};

export default JobMatch;
