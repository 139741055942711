import React, { useEffect, useRef } from "react";
import { Box, Typography, Avatar, Button } from "@mui/material";
import { styled } from "@mui/system";
import "../../styles/BotPage.css";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";

const ChatContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "400px",
  overflowY: "auto",
  backgroundColor: "inherit",
  borderRadius: "10px",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

const MessageContainer = styled(Box)(({ role }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
  flexDirection: role === "user" ? "row-reverse" : "row",
}));

const MessageBubble = styled(Box)(({ role }) => ({
  maxWidth: "70%",
  padding: "10px 15px",
  borderRadius: "15px",
  backgroundColor: role === "user" ? "#007aff" : "#1c1c1c",
  color: "#fff",
  marginLeft: role === "user" ? "10px" : "5px",
  marginRight: role === "user" ? "5px" : "10px",
  boxShadow: "0px 1px 5px rgba(0,0,0,0.2)",
  wordBreak: "break-word",
}));

const LiveTranscript = ({
  conversation,
  downloadTranscript,
  hasTranscript,
}) => {
  const chatEndRef = useRef(null);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [conversation]);

  return (
    <>
      <Box>
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            color: "#ffffff",
            fontSize: "16px",
            marginBottom: "20px",
          }}
        >
          Live Transcript
        </Typography>
        <ChatContainer>
          {conversation?.map((message, index) => (
            <MessageContainer key={index} role={message.role}>
              <Avatar
                alt={message.role}
                src={
                  message.role === "user"
                    ? "https://as1.ftcdn.net/v2/jpg/04/23/59/74/1000_F_423597477_AKCjGMtevfCi9XJG0M8jter97kG466y7.jpg"
                    : "https://img.freepik.com/premium-photo/woman-robot-with-mobile-her-hand_795294-16.jpg"
                }
              />
              <MessageBubble role={message.role}>
                <Typography variant="body1">{message.content}</Typography>
              </MessageBubble>
            </MessageContainer>
          ))}
          <div ref={chatEndRef} style={{ float: "left", clear: "both" }} />
        </ChatContainer>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            onClick={downloadTranscript}
            disabled={!hasTranscript}
            id="download-button"
            startIcon={<DownloadIcon />}
          >
            Download Transcript
          </Button>
          <Button
            variant="contained"
            disabled={!hasTranscript}
            id="email-button"
            startIcon={<EmailIcon />}
          >
            Send As Email
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default LiveTranscript;
