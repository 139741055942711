import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useData } from "../../contexts/DataContext";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#4caf50",
  color: "#fff",
  width: "150px",
  height: "50px",
  fontSize: "16px",
  marginTop: "20px",
  "&:hover": {
    backgroundColor: "#45a049",
  },
}));

const OutlinedButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  borderColor: "#fff",
  width: "150px",
  height: "50px",
  fontSize: "16px",
  marginTop: "20px",
  "&:hover": {
    borderColor: "#4caf50",
    color: "#4caf50",
  },
}));

const ConfirmationIcon = styled(Avatar)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "#4caf50",
  width: "120px",
  height: "120px",
  "& .MuiSvgIcon-root": {
    fontSize: "120px",
  },
}));

export default function Confirmation({ handleOpen }) {
  const { user } = useData();
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        padding: 4,
        color: "#ffffff",
      }}
    >
      <ConfirmationIcon>
        <CheckCircleOutlineIcon />
      </ConfirmationIcon>
      <Typography component="h1" variant="h4" sx={{ m: 4, fontWeight: "bold" }}>
        Please Confirm Your Details
      </Typography>
      <Typography
        variant="body1"
        sx={{ mb: 4, color: "rgba(255, 255, 255, 0.7)" }}
      >
        Before submitting, please review and confirm that all the information
        you've provided is correct.
      </Typography>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <StyledButton variant="contained" onClick={handleOpen}>
          Confirm
        </StyledButton>
        <OutlinedButton variant="outlined" onClick={handleBackToHome}>
          Home
        </OutlinedButton>
      </Box>
    </Box>
  );
}
