import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const LikeToWork = ({ companyInfo, responseData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!companyInfo || companyInfo.length === 0) {
    return null;
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % companyInfo.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + companyInfo.length) % companyInfo.length
    );
  };

  return (
    <>
    <Typography
    variant="body2"
    sx={{ textAlign: "center", color: "#ffffff", fontSize: "16px" }}
  >
    Like to work here
  </Typography>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        marginTop: 2,
        position: "relative",
        width: "600px",
        height: "500px",
        margin: "auto",
      }}
    >
      <IconButton
        onClick={handlePrevious}
        sx={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: -40,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "#fff",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
        }}
        disabled={currentIndex === 0}
      >
        <ArrowBackIosNewIcon />
      </IconButton>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <SummaryCard
          companyName={
            responseData && responseData?.result[currentIndex]?.company
          }
          item={companyInfo[currentIndex]}
        />
      </Box>

      <IconButton
        onClick={handleNext}
        sx={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          right: -40,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "#fff",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
        }}
        disabled={currentIndex === companyInfo.length - 1}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
    </>
  );
};

const SummaryCard = ({ companyName, item }) => {
  const [showFullSummary, setShowFullSummary] = useState(false);
  const [showFullConclusion, setShowFullConclusion] = useState(false);

  return (
    <>
  

    <Card
      sx={{
        minWidth: "300px",
        maxWidth: "400px",
        height: "100%",
        margin: "auto",
        backgroundColor: "#1c1c1c",
        color: "#ffffff",
        overflow: "auto",
        scrollbarWidth: "none",
        "&:hover": {
          backgroundColor: "#333333",
          transform: "scale(1.05)",
        },
        transition: "transform 0.2s",
        padding: 2,
      }}
    >
      <CardContent>
        <Typography variant="body1" gutterBottom>
          <strong>Company Name:</strong> {companyName || ""}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            display: showFullSummary ? "block" : "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: showFullSummary ? "none" : 2,
            textOverflow: "ellipsis",
          }}
        >
          <strong>Executive Summary:</strong> {item?.["Executive Summary"]}
        </Typography>
        <Button
          onClick={() => setShowFullSummary((prev) => !prev)}
          sx={{
            color: "skyblue",
            textTransform: "none",
            padding: 0,
            marginBottom: 1,
          }}
        >
          {showFullSummary ? "Show Less" : "Show More"}
        </Button>

        <Typography
          variant="body2"
          sx={{
            display: showFullConclusion ? "block" : "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: showFullConclusion ? "none" : 2,
            textOverflow: "ellipsis",
          }}
        >
          <strong>Conclusion:</strong> {item?.["Conclusion"]}
        </Typography>
        <Button
          onClick={() => setShowFullConclusion((prev) => !prev)}
          sx={{
            color: "skyblue",
            textTransform: "none",
            padding: 0,
            marginBottom: 1,
          }}
        >
          {showFullConclusion ? "Show Less" : "Show More"}
        </Button>

        <Accordion
          sx={{ backgroundColor: "#2c2c2c", color: "#fff", marginTop: 2 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
          >
            <Typography variant="body2">
              CEO Ratings and Business Outlook
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              <strong>CEO Name:</strong>{" "}
              {item?.["CEO Ratings and Business Outlook"]?.["CEO Name"]}
            </Typography>
            <Typography variant="body2">
              <strong>Percent Satisfied:</strong>{" "}
              {
                item?.["CEO Ratings and Business Outlook"]?.[
                  "Percent Satisfied"
                ]
              }
              %
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ backgroundColor: "#2c2c2c", color: "#fff" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
          >
            <Typography variant="body2">
              Analysis for Prospective Employees
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              {item?.["Analysis for Prospective Employees"]}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ backgroundColor: "#2c2c2c", color: "#fff" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
          >
            <Typography variant="body2">Implications for Employees</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {item?.["Implications for Employees"]?.map((implication, index) => (
              <Typography variant="body2" marginBottom={1} key={index}>
                {implication}
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
    </>
  );
};

export default LikeToWork;
