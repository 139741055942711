import React, { useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Grid,
  OutlinedInput,
  RadioGroup,
  FormControlLabel,
  Radio,
  Slider,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useData } from "../../contexts/DataContext";
import InfoIcon from "@mui/icons-material/Info";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.7)",
  marginBottom: theme.spacing(1),
  "&.Mui-focused": {
    color: "rgba(255, 255, 255, 0.7)",
  },
}));

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.23)",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.5)",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.5)",
  },
  color: "rgba(255, 255, 255, 0.9)",
  "&::placeholder": {
    color: "rgba(255, 255, 255, 0.5)",
  },
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-checked": {
    color: "#4caf50", // Green color for selected radio
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.9)",
  "&:hover": {
    color: "rgba(255, 255, 255, 1)",
  },
  "& .MuiFormControlLabel-label": {
    "&.Mui-focused": {
      color: "rgba(255, 255, 255, 0.9)",
    },
  },
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: "#4caf50",
  "& .MuiSlider-thumb": {
    backgroundColor: "#4caf50",
  },
  "& .MuiSlider-track": {
    backgroundColor: "#4caf50",
  },
  "& .MuiSlider-rail": {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
  },
  "& .MuiSlider-markLabel": {
    color: "rgba(255, 255, 255, 0.7)",
  },
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default function CurrentJobInfoForm() {
  const { updateFormData, formData } = useData();
  const initialData = formData?.form_info;

  const [formState, setFormState] = useState({
    salary: initialData.salary || "",
    bonus: initialData.bonus || "",
    bonusPercentage: initialData.bonusPercentage || "",
    salaryFromFirstJob: initialData.salaryFromFirstJob || "",
    jobSatisfaction: initialData.jobSatisfaction || 50,
    supportiveManager: initialData.supportiveManager || "",
    toxicCulture: initialData.toxicCulture || "",
    workEngagement: initialData.workEngagement || "",
    jobSearching: initialData.jobSearching || "",
    idealWorkEnvironment: initialData.idealWorkEnvironment || "",
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormState((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
    updateFormData(name, newValue);
  };

  const handleSliderChange = (event, newValue) => {
    setFormState((prevState) => ({
      ...prevState,
      jobSatisfaction: newValue,
    }));
    updateFormData("jobSatisfaction", newValue);
  };

  const marks = [
    { value: 0, label: "0" },
    { value: 20, label: "20" },
    { value: 40, label: "40" },
    { value: 60, label: "60" },
    { value: 80, label: "80" },
    { value: 100, label: "100" },
  ];

  return (
    <Box sx={{ color: "#ffffff", padding: 3 }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
        Current Job Information
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <StyledFormControl fullWidth>
            <StyledFormLabel htmlFor="salary">
              Current annual salary
            </StyledFormLabel>
            <StyledOutlinedInput
              id="salary"
              name="salary"
              type="number"
              value={formState.salary}
              onChange={handleChange}
              startAdornment="$"
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledFormControl fullWidth>
            <StyledFormLabel htmlFor="bonus">Annual bonus</StyledFormLabel>
            <StyledOutlinedInput
              id="bonus"
              name="bonus"
              type="number"
              value={formState.bonus}
              onChange={handleChange}
              startAdornment="$"
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledFormControl fullWidth>
            <StyledFormLabel htmlFor="bonusPercentage">
              Bonus %
              <StyledTooltip title="Optional: Enter bonus as a percentage">
                <InfoIcon
                  sx={{ fontSize: 16, ml: 1, verticalAlign: "middle" }}
                />
              </StyledTooltip>
            </StyledFormLabel>
            <StyledOutlinedInput
              id="bonusPercentage"
              name="bonusPercentage"
              type="number"
              value={formState.bonusPercentage}
              onChange={handleChange}
              endAdornment="%"
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledFormControl fullWidth>
            <StyledFormLabel htmlFor="salaryFromFirstJob">
              Salary from first job
            </StyledFormLabel>
            <StyledOutlinedInput
              id="salaryFromFirstJob"
              name="salaryFromFirstJob"
              type="number"
              value={formState.salaryFromFirstJob}
              onChange={handleChange}
              startAdornment="$"
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledFormControl fullWidth>
            <StyledFormLabel>Job Satisfaction</StyledFormLabel>
            <StyledSlider
              marks={marks}
              step={1}
              value={formState.jobSatisfaction}
              valueLabelDisplay="auto"
              min={0}
              max={100}
              onChange={handleSliderChange}
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledFormControl component="fieldset" fullWidth>
            <StyledFormLabel component="legend">
              Do you have a supportive manager?
            </StyledFormLabel>
            <RadioGroup
              name="supportiveManager"
              value={formState.supportiveManager}
              onChange={handleChange}
            >
              {["Yes", "No", "Unsure"].map((option) => (
                <StyledFormControlLabel
                  key={option}
                  value={option.toLowerCase()}
                  control={<StyledRadio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledFormControl component="fieldset" fullWidth>
            <StyledFormLabel component="legend">
              Is your company culture toxic?
            </StyledFormLabel>
            <RadioGroup
              name="toxicCulture"
              value={formState.toxicCulture}
              onChange={handleChange}
            >
              {["Yes", "No", "Somewhat"].map((option) => (
                <StyledFormControlLabel
                  key={option}
                  value={option.toLowerCase()}
                  control={<StyledRadio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledFormControl component="fieldset" fullWidth>
            <StyledFormLabel component="legend">
              Work engagement level:
            </StyledFormLabel>
            <RadioGroup
              name="workEngagement"
              value={formState.workEngagement}
              onChange={handleChange}
            >
              {[
                { value: "0-20", label: "0-20: Minimal effort, disengaged" },
                { value: "21-40", label: "21-40: Low engagement" },
                { value: "41-60", label: "41-60: Moderately engaged" },
                { value: "61-80", label: "61-80: Engaged and committed" },
                {
                  value: "81-100",
                  label: "81-100: Highly committed, go above and beyond",
                },
              ].map((option) => (
                <StyledFormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<StyledRadio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledFormControl component="fieldset" fullWidth>
            <StyledFormLabel component="legend">
              Are you currently job searching?
            </StyledFormLabel>
            <RadioGroup
              name="jobSearching"
              value={formState.jobSearching}
              onChange={handleChange}
            >
              {["Yes", "No", "Considering"].map((option) => (
                <StyledFormControlLabel
                  key={option}
                  value={option.toLowerCase()}
                  control={<StyledRadio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledFormControl fullWidth>
            <StyledFormLabel htmlFor="idealWorkEnvironment">
              Describe your ideal work environment
            </StyledFormLabel>
            <StyledOutlinedInput
              id="idealWorkEnvironment"
              name="idealWorkEnvironment"
              value={formState.idealWorkEnvironment}
              onChange={handleChange}
              placeholder="Describe environment"
              multiline
              minRows={3}
            />
          </StyledFormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
