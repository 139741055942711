import React, { useCallback, useEffect } from "react";
import { Box, Typography, Grid, Paper, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useData } from "../../contexts/DataContext";

const StyledBox = styled(Box)(({ theme }) => ({
  color: "#ffffff",
  padding: theme.spacing(3),
}));

const DropzonePaper = styled(Paper)(
  ({ isDragActive, isDragAccept, isDragReject }) => ({
    backgroundColor: isDragActive
      ? "rgba(255, 255, 255, 0.1)"
      : "rgba(255, 255, 255, 0.05)",
    border: isDragAccept
      ? "dashed 2px #4caf50"
      : isDragReject
      ? "dashed 2px #f44336"
      : "dashed 2px rgba(255, 255, 255, 0.3)",
    height: "175px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "background-color 0.3s, border 0.3s",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  })
);

const FileList = styled("ul")({
  listStyle: "none",
  marginTop: 10,
  padding: 0,
  width: "100%",
  maxHeight: "100px",
  overflowY: "auto",
  textAlign: "left",
  paddingLeft: "10px",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "-ms-overflow-style": "none",
  "scrollbar-width": "none",
});

const FileItem = styled("li")({
  padding: "0px 5px",
  borderRadius: "4px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "rgba(255, 255, 255, 0.7)",
  fontWeight: "bold",
  fontSize: "14px",
});

export default function JobMatchForm() {
  const { updateFiles, formData, removeFile } = useData();
  const initialData = formData.form_info;

  const onResumeDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        updateFiles("resume", acceptedFiles);
      }
    },
    [updateFiles]
  );

  const onJobDescriptionDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        updateFiles("job_description", acceptedFiles);
      }
    },
    [updateFiles]
  );

  const handleRemoveFile = (type, index) => {
    removeFile(type, index);
  };

  const resumeDropzone = useDropzone({
    onDrop: onResumeDrop,
    accept: ".pdf,.doc,.docx",
    multiple: true,
  });

  const jobDescriptionDropzone = useDropzone({
    onDrop: onJobDescriptionDrop,
    accept: ".pdf,.doc,.docx",
    multiple: true,
  });

  return (
    <StyledBox>
      <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
        Job Match Form
      </Typography>
      <Typography
        variant="subtitle1"
        color="rgba(255, 255, 255, 0.7)"
        sx={{ mb: 3 }}
      >
        Before we begin, please provide the following information:
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DropzonePaper
            {...resumeDropzone.getRootProps()}
            isDragActive={resumeDropzone.isDragActive}
            isDragAccept={resumeDropzone.isDragAccept}
            isDragReject={resumeDropzone.isDragReject}
          >
            <input {...resumeDropzone.getInputProps()} />
            <Box sx={{ textAlign: "center" }}>
              <CloudUploadOutlinedIcon
                fontSize="large"
                sx={{ color: "#4caf50" }}
              />
              <Typography sx={{ fontWeight: "bold", color: "#ffffff" }}>
                Drag & Drop Or Upload Your Resumes
              </Typography>
            </Box>
            <Box>
              <FileList>
                {formData.form_info.resume?.map((file, index) => (
                  <FileItem key={index}>
                    <Typography
                      noWrap
                      sx={{
                        maxWidth: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      {file.name}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveFile("resume", index);
                      }}
                    >
                      <CloseIcon sx={{ color: "#f44336" }} />
                    </IconButton>
                  </FileItem>
                ))}
              </FileList>
            </Box>
          </DropzonePaper>
        </Grid>
        <Grid item xs={12}>
          <DropzonePaper
            {...jobDescriptionDropzone.getRootProps()}
            isDragActive={jobDescriptionDropzone.isDragActive}
            isDragAccept={jobDescriptionDropzone.isDragAccept}
            isDragReject={jobDescriptionDropzone.isDragReject}
          >
            <input {...jobDescriptionDropzone.getInputProps()} />
            <Box sx={{ textAlign: "center" }}>
              <CloudUploadOutlinedIcon
                fontSize="large"
                sx={{ color: "#4caf50" }}
              />
              <Typography sx={{ fontWeight: "bold", color: "#ffffff" }}>
                Drag & Drop Or Upload Your Job Descriptions
              </Typography>
            </Box>
            <Box>
              <FileList>
                {formData.form_info.job_description?.map((file, index) => (
                  <FileItem key={index}>
                    <Typography
                      noWrap
                      sx={{
                        maxWidth: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      {file.name}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveFile("job_description", index);
                      }}
                    >
                      <CloseIcon sx={{ color: "#f44336" }} />
                    </IconButton>
                  </FileItem>
                ))}
              </FileList>
            </Box>
          </DropzonePaper>
        </Grid>
      </Grid>
    </StyledBox>
  );
}
