import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  OutlinedInput,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useData } from "../../contexts/DataContext";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.7)",
  marginBottom: theme.spacing(1),
  "&.Mui-focused": {
    color: "rgba(255, 255, 255, 0.7)",
  },
}));

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.23)",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.5)",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.5)",
  },
  color: "rgba(255, 255, 255, 0.9)",
  "&::placeholder": {
    color: "rgba(255, 255, 255, 0.5)",
  },
}));

export default function CareerAspirations() {
  const { updateFormData, formData } = useData();
  const initialData = formData?.form_info;

  const [formState, setFormState] = useState({
    fiveYears: initialData?.fiveYears || "",
    careerChanges: initialData?.careerChanges || "",
    dreamJob: initialData?.dreamJob || "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    updateFormData(name, value);
  };

  return (
    <Box sx={{ color: "#ffffff", padding: 3 }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
        Career Aspirations
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StyledFormControl fullWidth>
            <StyledFormLabel htmlFor="fiveYears">
              Where do you see yourself in 5 years?
            </StyledFormLabel>
            <StyledOutlinedInput
              id="fiveYears"
              name="fiveYears"
              value={formState.fiveYears}
              onChange={handleChange}
              placeholder="Describe where you see yourself in 5 years"
              multiline
              rows={3}
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledFormControl fullWidth>
            <StyledFormLabel htmlFor="careerChanges">
              What aspects of your career would you like to change or improve?
            </StyledFormLabel>
            <StyledOutlinedInput
              id="careerChanges"
              name="careerChanges"
              value={formState.careerChanges}
              onChange={handleChange}
              placeholder="Describe the aspects you want to change or improve"
              multiline
              rows={3}
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledFormControl fullWidth>
            <StyledFormLabel htmlFor="dreamJob">
              Describe your dream job
            </StyledFormLabel>
            <StyledOutlinedInput
              id="dreamJob"
              name="dreamJob"
              value={formState.dreamJob}
              onChange={handleChange}
              placeholder="Describe your dream job"
              multiline
              rows={3}
            />
          </StyledFormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
