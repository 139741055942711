import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Container,
  Avatar,
} from "@mui/material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { useNavigate } from "react-router-dom";
import LeftMenuLayout from "../Layout/LeftMenuLayout";
import { useData } from "../../contexts/DataContext";
import BotBgAnimation from "../Animation/BotBgAnimation";
import LoadingSpinner from "../LoadingSpinner";

const OnBoardingHome = () => {
  const navigate = useNavigate();
  const { isOnboardingComplete, spinner } = useData();

  return (
    <LeftMenuLayout>
      <BotBgAnimation />
      <Container
        maxWidth="lg"
        sx={{ minHeight: "100vh", display: "flex", alignItems: "center" }}
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={8} lg={6}>
            <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", md: "flex-start" },
                  mb: 4,
                }}
              >
                <Avatar
                  sx={{ bgcolor: "#4caf50", width: 60, height: 60, mr: 2 }}
                >
                  <SmartToyIcon fontSize="large" />
                </Avatar>
                <Typography
                  variant="h4"
                  sx={{
                    color: "text.primary",
                    fontWeight: "bold",
                    background: "linear-gradient(45deg, #00ffcc, #ffffffb5)",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                  }}
                >
                  AI Career Assistant
                </Typography>
              </Box>
              <Typography
                variant="h2"
                component="h1"
                sx={{
                  fontWeight: 700,
                  background: "linear-gradient(45deg, #4caf50, #2196f3)",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                  mb: 3,
                }}
              >
                Your AI-Powered Career Guide
              </Typography>
              <Typography variant="h5" sx={{ mb: 4, color: "text.secondary" }}>
                Unlock your potential with personalized career advice, job
                matching, and professional growth insights.
              </Typography>
              <Button
                variant="contained"
                size="large"
                startIcon={<RocketLaunchIcon />}
                sx={{
                  fontWeight: "bold",
                  color: "common.white",
                  background: "linear-gradient(45deg, #4caf50, #2196f3)",
                  padding: "12px 24px",
                  borderRadius: "30px",
                  boxShadow:
                    "0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0)",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    transform: "translateY(-3px)",
                    boxShadow:
                      "0 8px 25px 0 rgba(61, 71, 82, 0.2), 0 0 0 0 rgba(0, 127, 255, 0)",
                  },
                }}
                onClick={() =>
                  navigate(
                    isOnboardingComplete
                      ? "/job-application"
                      : "/career-onboarding"
                  )
                }
              >
                Start Your AI-Guided Journey
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {spinner && <LoadingSpinner />}
    </LeftMenuLayout>
  );
};

export default OnBoardingHome;
