import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner'; // Assuming you have a LoadingSpinner component

const PrivateRoute = ({ element, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const email = localStorage.getItem('email');
  const password = localStorage.getItem('password');
  const AccessToken = localStorage.getItem('AccessToken');

  useEffect(() => {
    const checkAuth = async () => {
      if ((!email || !password) && !AccessToken) {
        setIsLoading(false);
        return;
      }

      if (email && AccessToken) {
        try {
          const response = await fetch(`https://coachdemo.hrbrain.ai/api/check-subscription`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email }),
          });
          
          const subscriptionData = await response.json();
          setHasActiveSubscription(subscriptionData.has_active_subscription);
        } catch (error) {
          console.error("Error checking subscription:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [email, password, AccessToken]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if ((!email || !password) && !AccessToken) {
    return <Navigate to="/" replace />;
  }

  if (!hasActiveSubscription) {
    return <Navigate to="/stripe" replace />;
  }

  return element;
};

export default PrivateRoute;